import { formatInternationalPhoneNumber } from 'src/lib/phone.utils'
import TextInput from 'src/pages/ApplicantDetailsPage/tabs/ApplicationTab/components/TextInput/TextInput'

const CompanyInfoCard = ({ voe }) => {
  return (
    <div className="flex flex-col gap-6 rounded-lg border border-doubleNickel-gray-200">
      <div className="flex flex-col gap-6 p-6">
        <div className="text-md font-semibold text-doubleNickel-gray-900">
          Company Information
        </div>
        <div className="flex flex-row justify-between gap-4">
          <TextInput
            label="Company Name"
            value={voe.companyName}
            editing={false}
            className="flex-1"
          />
          <TextInput
            label="DoT Number"
            className="flex-1"
            value={voe.dotNumber}
            editing={false}
          />
          <TextInput
            label="Contact Name"
            value={voe.contactName}
            editing={false}
            className="flex-1"
          />
          <TextInput
            label="Email"
            value={voe.email}
            className="flex-1"
            editing={false}
          />
        </div>
        <div className="flex flex-row justify-between gap-4">
          <TextInput
            label="Phone Number"
            className="flex-1"
            value={formatInternationalPhoneNumber(voe.phoneNumber)}
            editing={false}
          />
          <TextInput
            label="Fax Number"
            className="flex-1"
            value={formatInternationalPhoneNumber(voe.faxNumber)}
            editing={false}
          />
          <TextInput
            label="Full Address"
            className="flex-1"
            value={
              voe.companyAddress
                ? voe.companyAddress.street +
                  ', ' +
                  voe.companyAddress.city +
                  ', ' +
                  voe.companyAddress.state
                : '-'
            }
            editing={false}
          />
          <TextInput className="flex-1 opacity-0" label="spacer" />
        </div>
      </div>
    </div>
  )
}

export default CompanyInfoCard
