import { InputWrapper, PasswordInput } from '@mantine/core'

import { navigate, routes } from '@redwoodjs/router'

import Button from 'src/components/Buttons/Button/Button'
import DateInput from 'src/components/Inputs/DateInput/DateInput'
import IconNavigate from 'src/icons/IconNavigate'
import TextInput from 'src/pages/ApplicantDetailsPage/tabs/ApplicationTab/components/TextInput/TextInput'

const DriverInfoCard = ({ voe, noApplicantLink }) => {
  return (
    <div className="flex flex-col gap-6 rounded-lg border border-doubleNickel-gray-200">
      <div className="flex flex-col gap-6 p-6">
        <div className="text-md font-semibold text-doubleNickel-gray-900">
          Driver Information
        </div>
        <div className="flex flex-row justify-between gap-4">
          {voe.applicant && !noApplicantLink ? (
            <InputWrapper
              label="Driver Name"
              classNames={{
                root: 'flex flex-col flex-1 items-start',
                label: 'text-xs font-medium text-doubleNickel-gray-700',
              }}
            >
              <Button
                variant="link"
                righticon={
                  <IconNavigate className="fill-none stroke-doubleNickel-brand-500" />
                }
                text={voe.applicant.firstName + ' ' + voe.applicant.lastName}
                onClick={() => {
                  navigate(
                    routes.applicantDetails({
                      id: voe.applicant.applicantId,
                      tab: 'application',
                    })
                  )
                }}
              />
            </InputWrapper>
          ) : (
            <TextInput
              label="Driver Name"
              value={
                voe?.applicant
                  ? voe.applicant.firstName + ' ' + voe.applicant.lastName
                  : voe.driverName
              }
              editing={false}
              className="flex-1"
              readOnly
            />
          )}

          <PasswordInput
            label="SSN"
            className="flex-1"
            value={voe.ssn}
            variant="unstyled"
            classNames={{
              label: 'text-xs font-medium text-doubleNickel-gray-700',
            }}
            readOnly
          />

          <DateInput
            label="Date of Birth"
            className="flex-1"
            value={new Date(voe.dob)}
            editing={false}
          />
        </div>
      </div>
    </div>
  )
}

export default DriverInfoCard
