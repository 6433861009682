import { Divider } from '@mantine/core'

import Empty from 'src/components/Feedback/Empty/Empty'
import QuestionRow from 'src/components/Inputs/QuestionRow/QuestionRow'
import MonthInput from 'src/pages/ApplicantDetailsPage/tabs/ApplicationTab/components/MonthInput/MonthInput'
import TextInput from 'src/pages/ApplicantDetailsPage/tabs/ApplicationTab/components/TextInput/TextInput'

import ConflictingInputWrapper from './ConflictingInputWrapper'

const ResponseContentColumn = ({ editing, form, request }) => {
  const emptyForm = Object.keys(form.values).length === 0

  if (emptyForm) {
    return <Empty title={'No Response Found'} />
  }

  return (
    <div className="flex flex-1 flex-col gap-4">
      <div className="text-md px-4 font-semibold text-doubleNickel-gray-600">
        Response
      </div>
      <ConflictingInputWrapper
        request={request.positionHeld}
        response={form.values.positionHeld}
      >
        <TextInput
          label="Position Held"
          required
          editing={editing}
          {...form.getInputProps(`positionHeld`)}
        />
      </ConflictingInputWrapper>
      <ConflictingInputWrapper
        request={new Date(request.startDate).toDateString()}
        response={form.values.startDate.toDateString()}
      >
        <MonthInput
          label="Employment Start Date"
          required
          value={form.values.startDate}
          handleChange={(value) => form.setFieldValue('startDate', value)}
          editing={editing}
        />
      </ConflictingInputWrapper>
      <ConflictingInputWrapper
        request={
          request.endDate ? new Date(request.endDate).toDateString() : ''
        }
        response={form.values?.endDate?.toDateString() || ''}
      >
        <MonthInput
          label="Employment End Date"
          value={form.values.endDate}
          handleChange={(value) => form.setFieldValue('endDate', value)}
          editing={editing}
          clearable
        />
      </ConflictingInputWrapper>

      <Divider />
      <div className="text-md px-4 font-medium text-doubleNickel-gray-500">
        Key Questions
      </div>
      <ConflictingInputWrapper
        isQuestion={true}
        request={request.isDriverTerminated}
        response={form.values.isDriverTerminated}
      >
        <QuestionRow
          text="Was the driver terminated?"
          editing={editing}
          required
          value={form.values.isDriverTerminated}
          handleChange={(value) =>
            form.setFieldValue(`isDriverTerminated`, value === 'true')
          }
        />
      </ConflictingInputWrapper>
      <ConflictingInputWrapper
        isQuestion={true}
        request={request.isDriverSubjectToFMCSRs}
        response={form.values.isDriverSubjectToFMCSRs}
      >
        <QuestionRow
          text="Was the driver subject to FMCSRs while employed?"
          editing={editing}
          required
          value={form.values.isDriverSubjectToFMCSRs}
          handleChange={(value) =>
            form.setFieldValue(`isDriverSubjectToFMCSRs`, value === 'true')
          }
        />
      </ConflictingInputWrapper>
      <ConflictingInputWrapper
        isQuestion={true}
        request={request.isJobDesignatedAsSafetySensitiveFunctionUnderDOT}
        response={form.values.isJobDesignatedAsSafetySensitiveFunctionUnderDOT}
      >
        <QuestionRow
          text="Was the driver's job designated as a safety-sensitive function in a DoT regulated mode subject to Drug and Alcohol testing per 49 CFR Part 40?"
          editing={editing}
          required
          value={form.values.isJobDesignatedAsSafetySensitiveFunctionUnderDOT}
          handleChange={(value) =>
            form.setFieldValue(
              `isJobDesignatedAsSafetySensitiveFunctionUnderDOT`,
              value === 'true'
            )
          }
        />
      </ConflictingInputWrapper>
      <ConflictingInputWrapper
        request={request.reasonForLeaving}
        response={form.values.reasonForLeaving}
      >
        <TextInput
          label="Reason for Leaving"
          editing={editing}
          required
          {...form.getInputProps(`reasonForLeaving`)}
        />
      </ConflictingInputWrapper>
    </div>
  )
}

export default ResponseContentColumn
