import { Divider } from '@mantine/core'

import Empty from 'src/components/Feedback/Empty/Empty'
import QuestionRow from 'src/components/Inputs/QuestionRow/QuestionRow'
import MonthInput from 'src/pages/ApplicantDetailsPage/tabs/ApplicationTab/components/MonthInput/MonthInput'
import TextInput from 'src/pages/ApplicantDetailsPage/tabs/ApplicationTab/components/TextInput/TextInput'

import ConflictingInputWrapper from './ConflictingInputWrapper'

const RequestContentColumn = ({ request, form }) => {
  //If there is no response, we set the response values to the request values to prevent conflicts in the ConflictingInputWrapper
  const responseContent =
    Object.keys(form.values).length > 0 ? form.values : request

  if (request === null) {
    return <Empty title={'No Request Found'} />
  }

  return (
    <div className="flex flex-1 flex-col gap-4">
      <div className="text-md px-4 font-semibold text-doubleNickel-gray-600">
        Incoming Request
      </div>
      <ConflictingInputWrapper
        request={request.positionHeld}
        response={responseContent.positionHeld}
      >
        <TextInput
          label="Position Held"
          value={request.positionHeld}
          editing={false}
        />
      </ConflictingInputWrapper>
      <ConflictingInputWrapper
        request={new Date(request.startDate).toDateString()}
        response={new Date(responseContent.startDate).toDateString()}
      >
        <MonthInput
          label="Employment Start Date"
          value={request.startDate}
          editing={false}
        />
      </ConflictingInputWrapper>
      <ConflictingInputWrapper
        request={
          request.endDate ? new Date(request.endDate).toDateString() : ''
        }
        response={
          responseContent.endDate
            ? new Date(responseContent.endDate)?.toDateString()
            : ''
        }
      >
        <MonthInput
          label="Employment End Date"
          value={request.endDate}
          editing={false}
        />
      </ConflictingInputWrapper>
      <Divider />
      <div className="text-md px-4 font-medium text-doubleNickel-gray-500">
        Key Questions
      </div>
      <ConflictingInputWrapper
        isQuestion={true}
        request={request.isDriverTerminated}
        response={responseContent.isDriverTerminated}
      >
        <QuestionRow
          text="Was the driver terminated?"
          value={request.isDriverTerminated}
          editing={false}
        />
      </ConflictingInputWrapper>
      <ConflictingInputWrapper
        isQuestion={true}
        request={request.isDriverSubjectToFMCSRs}
        response={responseContent.isDriverSubjectToFMCSRs}
      >
        <QuestionRow
          text="Was the driver subject to FMCSRs while employed?"
          value={request.isDriverSubjectToFMCSRs}
          editing={false}
        />
      </ConflictingInputWrapper>
      <ConflictingInputWrapper
        isQuestion={true}
        request={request.isJobDesignatedAsSafetySensitiveFunctionUnderDOT}
        response={
          responseContent.isJobDesignatedAsSafetySensitiveFunctionUnderDOT
        }
      >
        <QuestionRow
          text="Was the driver's job designated as a safety-sensitive function in a DoT regulated mode subject to Drug and Alcohol testing per 49 CFR Part 40?"
          value={request.isJobDesignatedAsSafetySensitiveFunctionUnderDOT}
          editing={false}
        />
      </ConflictingInputWrapper>
      <ConflictingInputWrapper
        request={request.reasonForLeaving}
        response={responseContent.reasonForLeaving}
      >
        <TextInput
          label="Reason for Leaving"
          editing={false}
          value={request.reasonForLeaving}
        />
      </ConflictingInputWrapper>
    </div>
  )
}

export default RequestContentColumn
