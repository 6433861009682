import IconAlertTriangle from 'src/icons/IconAlertTriangle'

const ConflictingInputWrapper = ({
  request,
  response,
  isQuestion = false,
  children,
}) => {
  const isConflicting = request !== response

  return (
    <div
      className={`flex rounded-lg
      ${isConflicting && 'bg-doubleNickel-warning-50'}
      ${isQuestion ? 'flex-col gap-2' : 'flex-row items-center gap-4'}
      px-4 py-2`}
    >
      <div className="flex-1">{children}</div>
      <div className="mt-auto flex-1">
        {isConflicting && (
          <div className={`${!isQuestion && 'mb-2'} flex flex-row gap-3`}>
            <IconAlertTriangle className="fill-none stroke-doubleNickel-warning-600" />
            <div className="text-sm font-semibold text-doubleNickel-warning-600 ">
              Conflicting Information
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ConflictingInputWrapper
